import { gql } from '@lojinha/palantir'

export const cartFragment = gql`
  fragment CartFragment on Cart {
    id
    user
    items {
      totalCount
      nodes {
        id
        quantity
        item {
          id
          imageUrl
          kind
          sku
          name
          price
          priceFrom
          ... on Meal {
            mainProduct
            sideProducts
          }
        }
      }
    }
    billing {
      subtotal
    }
  }
`

export const userFragment = gql`
  fragment User on User {
    email
    isFirstBuy
    addresses {
      nodes {
        ... on DeliveryAddress {
          lastUsedAt
          street
          number
          zipCode
          complement
          neighborhood
          city
          state
          country
          coordinates {
            latitude
            longitude
          }
          nearestDistributionCenter {
            id
          }
        }
      }
    }
  }
`

export const FREE_SHIPPING_VALUE = gql`
  query FreeShippingValue {
    freeShippingValue
  }
`

export const VIEWER_CONTEXT = gql`
  query VitrineContext {
    viewer {
      user {
        id
        name
        email
        isFirstBuy
        referralRewardAmount
        addresses {
          nodes {
            ... on DeliveryAddress {
              lastUsedAt
              street
              number
              zipCode
              complement
              neighborhood
              city
              state
              country
              coordinates {
                latitude
                longitude
              }
              nearestDistributionCenter {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CART = gql`
  query GetToCart($input: ClaimCartInput!) {
    viewer {
      cart(input: $input) {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`

export const CLAIM_SAVED_CART_MUTATION = gql`
  mutation ClaimSavedCart($input: ClaimCartInput!) {
    claimCart(input: $input) {
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`

export const GET_ADDRESSES_AND_CART = gql`
  query GetAddressesAndCart {
    viewer {
      user {
        ...User
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${userFragment}
  ${cartFragment}
`
