import { useEffect } from 'react'
import { useLojinhaContext } from '@lojinha/context'
import { Address } from '@lojinha/context/src/types'
import { makeAddressId } from '@lojinha/location/src/address/logic'
import {
  AddressKind,
  useApolloClient,
  VitrineContextQuery,
} from '@lojinha/palantir'
import { beholder } from '@lojinha/beholder'
import { getIsLivupper } from '../helpers/isLivupper'
import { getLocalBag, parseAddresses } from './logic'
import { VIEWER_CONTEXT } from './queries'

export const SetupVitrineContext = () => {
  const {
    bag,
    setBag,
    setAddress,
    setCenterId,
    setIsContextLoaded,
    setIsFirstBuy,
    setIsLivupper,
    setReferralRewardAmount,
    setAllAddresses,
    isAuthenticated,
    setUser,
  } = useLojinhaContext()

  const client = useApolloClient()

  const getContext = async () => {
    const { data } = await client.query<VitrineContextQuery>({
      query: VIEWER_CONTEXT,
    })

    const cartItems =
      bag?.cartItems.map(cartItem => ({
        quantity: cartItem.quantity,
        sku: cartItem.item.sku,
        price: cartItem.item.price,
      })) || []

    const { user } = data.viewer
    beholder.shot('setUser', {
      id: user.id,
      email: user.email,
      cartItems,
    })

    return {
      userName: data?.viewer?.user?.name,
      userEmail: data?.viewer?.user?.email,
      isFirstBuy: data?.viewer?.user?.isFirstBuy ?? true,
      referralRewardAmount: data?.viewer?.user?.referralRewardAmount as number,
      allUserAddresses: data?.viewer?.user?.addresses?.nodes,
      lastUserAddress: data?.viewer?.user?.addresses?.nodes[0],
    }
  }

  const setupInitialContext = async () => {
    const localBag = getLocalBag()

    if (localBag) setBag(localBag)

    const {
      userEmail,
      userName,
      referralRewardAmount,
      allUserAddresses,
      lastUserAddress,
      isFirstBuy,
    } = await getContext()

    setReferralRewardAmount(referralRewardAmount)
    setUser(userName || '', userEmail)

    if (allUserAddresses) {
      const allAddresses = parseAddresses(allUserAddresses)

      setAllAddresses(allAddresses)
    }

    if (lastUserAddress?.__typename === 'DeliveryAddress') {
      const deliveryAddress = {
        ...lastUserAddress,
        id: makeAddressId(lastUserAddress),
      } as Address

      setAddress({
        ...deliveryAddress,
        kind: AddressKind.Delivery,
      })

      const deliveryCenterId = lastUserAddress?.nearestDistributionCenter?.id
      deliveryCenterId && setCenterId(deliveryCenterId)
    }

    if (userEmail) {
      const isLivupper = getIsLivupper(userEmail)
      setIsLivupper(isLivupper)
    }

    setIsFirstBuy(isFirstBuy)
    setIsContextLoaded(true)
  }

  useEffect(() => {
    if (isAuthenticated) setupInitialContext()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return null
}

export default SetupVitrineContext
