import { beholder, Trackers } from '@lojinha/beholder'
import { ContextProvider } from '@lojinha/context'
import { GlobalStyle, theme } from '@lojinha/design-system'
import '@lojinha/design-system/dist/assets/base.css'
import { RemoteConfigSetup } from '@lojinha/firebase'
import { Zendesk } from '@lojinha/zendesk'
import Head from 'next/head'
import { Router } from 'next/router'
import React, { useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ThemeProvider } from 'styled-components'
import { withApolloClient, ApolloProvider, User } from '@lojinha/palantir'
import {
  AppWithApolloCtx,
  AppWithApolloProps,
} from '@lojinha/palantir/src/apolloTypes'
import { IS_NOW, IS_SERVER } from '@lojinha/helpers'
import { captureException, SentryError } from '@lojinha/sentry'
import { t } from '../dictionary'
import SetupVitrineContext from '../core/setup-vitrine-context'
import '../modules/cms/cms.css'
import { VIEWER_CONTEXT } from '../core/queries'
import {
  getAddressFromUser,
  getDistributionCenterFromUser,
  getProfileFromUser,
} from '../helpers/user'

export type VitrineInitialProps = {
  user?: User
  authToken: string | null
  userAgent?: string
  isMobileUserAgent?: boolean
}

const routerOnChangeEvent = () => {
  // Shot page view event
  beholder.shot('trackPageView', {
    path: window.location.pathname,
  })
}

export const VitrinePageApp = ({
  Component,
  apolloClient,
  pageProps,
  appProps,
}: AppWithApolloProps<VitrineInitialProps>) => {
  useEffect(() => {
    Router.events.on('routeChangeComplete', routerOnChangeEvent)
    return () => {
      Router.events.off('routeChangeComplete', routerOnChangeEvent)
    }
  }, [])

  const { authToken, user } = appProps

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.RECAPTCHA_CLIENT_KEY}
      language={'pt-BR'}
    >
      <ThemeProvider theme={theme}>
        <Head>
          <link
            rel="shortcut icon"
            href={`${IS_NOW ? '/apps/vitrine' : ''}/favicon.png`}
          />
          <link href={process.env.WEBSITE_ADDRESS} rel="canonical" />
          <title>{t.title}</title>
          <meta name="description" content={t.metaDescription} />
          <meta name="og:description" content={t.metaDescription} />
          <meta
            property="og:image"
            content="https://s3-sa-east-1.amazonaws.com/livup-public/site/images/imagem-compartilhamento-site-liv-up.jpg"
          />
          {process.env.SITE_ENV !== 'staging' && (
            <base href={process.env.WEBSITE_ADDRESS} />
          )}
        </Head>
        <ApolloProvider client={apolloClient}>
          <ContextProvider
            isAuthenticated={!!authToken}
            isFirstBuy={user?.isFirstBuy ?? true}
            centerId={getDistributionCenterFromUser(user)}
            address={getAddressFromUser(user)}
            user={getProfileFromUser(user)}
            referralRewardAmount={
              user?.referralRewardAmount as number | undefined
            }
            userAgent={appProps.userAgent}
            isMobileUserAgent={appProps.isMobileUserAgent}
          >
            <GlobalStyle />
            <Component {...pageProps} />
            <SetupVitrineContext {...pageProps} />
            <Trackers />
            <Zendesk />
            <RemoteConfigSetup />
          </ContextProvider>
        </ApolloProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}

VitrinePageApp.getInitialProps = async (ctx: AppWithApolloCtx) => {
  const { authToken, apolloClient } = ctx
  let user: User | undefined

  if (IS_SERVER && authToken) {
    try {
      const { data } = await apolloClient.query({
        query: VIEWER_CONTEXT,
      })
      user = data?.viewer.user
    } catch (error) {
      captureException(error as SentryError, { ctx })
    }
  }

  const userAgent =
    (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent) || ''

  const isMobileUserAgent = !!userAgent?.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  )

  return {
    authToken,
    user,
    userAgent,
    isMobileUserAgent,
  }
}

export default withApolloClient<VitrineInitialProps>(VitrinePageApp)
