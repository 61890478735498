import { beholder } from '@lojinha/beholder'
import { captureException } from '@lojinha/sentry'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ZendeskSettings, ZendeskWidget } from '../zendesk'

declare global {
  interface Window {
    zESettings?: ZendeskSettings
    zE?: ZendeskWidget
  }
}

export const zendeskStart = () => {
  return new Promise(res => {
    if (window.zE) {
      return res(true)
    }

    if (document.getElementById('ze-snippet')) {
      return res(false)
    }

    const el = document.createElement('script')
    el.type = 'text/javascript'
    el.id = 'ze-snippet'
    el.src = `//static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_KEY}`
    el.async = true
    document.getElementsByTagName('head')[0].appendChild(el)

    const zEInterval = setInterval(() => {
      if (window.zE) {
        window.zE('webWidget', 'hide')

        // Beholder setup functions to listen zendesk hooks
        window.zE('webWidget:on', 'open', () => {
          beholder.shot('chatOpened', null)
        })

        window.zE('webWidget:on', 'close', () => {
          beholder.shot('chatClosed', null)
        })
        clearInterval(zEInterval)
        return res(true)
      }
    }, 100)
  })
}

const zendeskSetup = () => {
  // Beholder functions
  beholder.watch('openChat', async () => {
    if (sessionStorage.getItem('disable-trackers') === 'true') {
      return
    }

    const loaded = await zendeskStart()
    if (loaded && window.zE) {
      window.zE('webWidget', 'show')
      window.zE('webWidget', 'open')
    }
  })

  beholder.watch('chatClosed', () => {
    if (sessionStorage.getItem('disable-trackers') === 'true') {
      return
    }

    if (window.zE) {
      window.zE('webWidget', 'hide')
    }
  })
}

let setupInitialized = false

const protectedSetup = (setupFunc: () => void) => {
  try {
    setupFunc()
  } catch (error) {
    captureException(error)
  }
}

export const Zendesk = () => {
  const { query } = useRouter()

  useEffect(() => {
    if (query['disable-trackers']) {
      sessionStorage.setItem(
        'disable-trackers',
        query['disable-trackers'] as string
      )
    }
  }, [query])

  useEffect(() => {
    if (sessionStorage.getItem('disable-trackers') === 'true') {
      return
    }

    if (setupInitialized) return
    setupInitialized = true
    protectedSetup(() => zendeskSetup())
  }, [])

  return null
}
